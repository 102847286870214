import React, { FC } from 'react';
import { IconFinderLightBackground, Text } from '@ftdr/blueprint-components-react';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';

export const PageNotFound: FC = () => {
  return (
    <Page>
      <PageContent>
        <div className='flex flex-col items-center'>
          <IconFinderLightBackground className='mt-10' />
          <Text variant='heading-01' className='my-2 text-error-500' textTemplateKey='PAGE_NOT_FOUND_HEADING' />
          <Text variant='heading-05' textTemplateKey='PAGE_NOT_FOUND_DESCRIPTION' />
        </div>
      </PageContent>
    </Page>
  );
};
