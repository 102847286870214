import { FC, useContext } from 'react';
import {
  AhsLogomarkMono,
  GlobalFooter,
  GlobalFooterLinkProps,
  GlobalFooterMinimalFooterRowPreset,
  GlobalFooterSocialMediaLinksModule,
  HsaLogoColor
} from '@ftdr/blueprint-components-react';
import { Brand } from '../../constants/Brand';
import configHandler from '../../services/config';
import { ZestyContext } from '../../contexts/ZestyContext';
import { FooterItem } from '../../types/footer';

const Footer: FC = () => {
  const brand = configHandler.config?.site.name;

  const footerContent = useContext(ZestyContext);

  const getFooterLogo = () => {
    return brand === Brand.AHS ? (
      <AhsLogomarkMono height={50} color='white' />
    ) : (
      <HsaLogoColor height={50} color='white' />
    );
  };

  const mapLinks = (link: FooterItem): GlobalFooterLinkProps => ({
    children: link.menuText,
    href: link.menuLink,
    external: true,
    underline: 'always'
  });

  return footerContent ? (
    <div className={brand === Brand.AHS ? 'bg-primary-800' : 'bg-white'}>
      <div className='container mx-auto'>
        <GlobalFooter
          variant={brand === Brand.AHS ? 'dark' : 'light'}
          className={brand !== Brand.AHS ? 'bg-white border-t-1 border-error' : ''}>
          <div className='pb-7 md:pb-0 md:flex flex-row justify-between items-center w-full'>
            <GlobalFooterMinimalFooterRowPreset
              logoRenderer={getFooterLogo}
              legalText={footerContent.legalText}
              links={footerContent.links?.map(mapLinks)}
            />
            <GlobalFooterSocialMediaLinksModule
              facebook={footerContent.social?.Facebook}
              instagram={footerContent.social?.Instagram}
              twitter={footerContent.social?.Twitter}
            />
          </div>
        </GlobalFooter>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Footer;
