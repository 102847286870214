import { FC } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Root from './pages/Root';
import { PageRoute } from './constants/common';
import { PageNotFound } from './pages/PageNotFound';

const routes = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <PageNotFound />,
    children: [
      {
        path: PageRoute.EZLink,
        async lazy() {
          const { EZLink } = await import('./pages/EZLink');
          return {
            Component: EZLink
          };
        }
      },
      {
        path: PageRoute.Quote,
        async lazy() {
          const { Quote } = await import('./pages/Quote');
          return {
            Component: Quote
          };
        }
      },
      {
        path: PageRoute.AddPaymentMethod,
        async lazy() {
          const { AddPaymentMethod } = await import('./pages/AddPaymentMethod');
          return {
            Component: AddPaymentMethod
          };
        }
      },
      {
        path: PageRoute.Confirmation,
        async lazy() {
          const { Confirmation } = await import('./pages/Confirmation');
          return {
            Component: Confirmation
          };
        }
      }
    ]
  }
]);

const AppRoutes: FC = () => <RouterProvider router={routes} />;

export default AppRoutes;
