export enum SessionStorageKey {
  GuestToken = 'GuestToken',
  EZLinkOTP = 'EZLinkOTP'
}

export enum PageRoute {
  EZLink = 'ez-link/:otp',
  Quote = 'quote/:otp',
  AddPaymentMethod = 'add-payment-method/:otp',
  Confirmation = 'confirmation',
  PageNotFound = 'page-not-found'
}

export const LANGUAGE = {
  en_US: 'en_US',
  es_US: 'es_US'
};
