import axios, { AxiosError, AxiosInstance, CreateAxiosDefaults } from 'axios';
import ConfigService from './config';
import { extend } from '../utils/utils';
import querystring from 'querystring';

export class ServiceException {
  message: string;
  name: string;
  isAxiosError: boolean = false;
  request;
  response;

  constructor(error: AxiosError) {
    this.message = error.message;
    this.name = error.name || 'ServiceException';

    if (error.isAxiosError) {
      this.isAxiosError = true;
      this.request = error.request;
      this.response = error.response;
    }
  }
}

export default class GenericService {
  ServiceException = ServiceException;
  name: string;
  axios: AxiosInstance;

  constructor(name: string) {
    this.name = name;
    this.axios = this.createAxiosInstance();
  }

  /*
   * Creates a new axios instance using provided config
   * Adds a request interceptor to add the service config as a base request config
   */
  createAxiosInstance(config?: CreateAxiosDefaults) {
    const instance = axios.create({
      ...config,
      paramsSerializer: params => querystring.stringify(params)
    });

    instance.interceptors.request.use(config => {
      const serviceConfig = this.getServiceConfig();

      return extend({}, serviceConfig, config);
    });

    instance.interceptors.response.use(response => response.data);

    // Set a common error handler that can be removed and replaced with another one by access token interceptors
    instance.interceptors.response.use(null, error => Promise.reject(this.handleError(error)));

    return instance;
  }

  /*
   * Gets the config for this service from the ConfigService
   */
  getServiceConfig() {
    return Object.assign({}, ConfigService.config?.services?.['*'], ConfigService.config?.services?.[this.name]);
  }

  /*
   * Logs the error to the console and returns an exception
   */
  handleError(e: AxiosError) {
    if (e.response) {
      console.error(`[Service Error: ${this.name}] Not OK response: ${e.response.status} ${e.response.statusText}`);
    } else if (e.request) {
      console.error(`[Service Error: ${this.name}] No response:`, e.request);
    } else {
      console.error(`[Service Error: ${this.name}] Uncaught exception: ${e.message}`);
    }

    return new ServiceException(e);
  }
}
