import { FC, PropsWithChildren } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const Page: FC<PropsWithChildren> = ({ children }) => (
  <div className='w-full'>
    <Header />
    <div className='mt-20'>{children}</div>
    <Footer />
  </div>
);

export default Page;
