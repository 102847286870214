import { ReactNode, createContext } from 'react';
import { FooterContent } from '../types/footer';

export const ZestyContext = createContext<FooterContent | undefined>(undefined);

interface Props {
  footerContent: FooterContent;
  children: ReactNode;
}

export const ZestyProvider = ({ footerContent, children }: Props) => {
  return <ZestyContext.Provider value={footerContent}>{children}</ZestyContext.Provider>;
};
