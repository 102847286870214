import { ConfigData, ServiceDetails } from '../types/config';

let cachedConfig: ConfigData;

class ConfigService {
  set config(config: ConfigData) {
    cachedConfig = config;
  }

  get config(): ConfigData {
    return cachedConfig;
  }

  get selfServiceAPIConfig(): ServiceDetails {
    return Object.assign({}, this.config?.services?.['*']);
  }

  get envSiteConfig(): string {
    return this.config?.env;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ConfigService();
