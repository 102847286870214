import { FC, PropsWithChildren, createContext, useContext, useState } from 'react';
import { selfservicepb } from '@ftdr/selfservice-js-client';

export interface StoreContextValue {
  ezLinkData: selfservicepb.IQuotePaymentResponse | null;
  setEzLinkData: (quoteResponse: selfservicepb.IQuotePaymentResponse) => void;
}

const StoreContext = createContext<StoreContextValue>({
  setEzLinkData: _ => null,
  ezLinkData: null
});

export const useStoreContext = () => useContext(StoreContext);

export const StoreContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [ezLinkData, setEzLinkData] = useState<selfservicepb.IQuotePaymentResponse | null>(null);
  return <StoreContext.Provider value={{ ezLinkData, setEzLinkData }}>{children}</StoreContext.Provider>;
};
