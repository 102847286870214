export enum Brand {
  FrontDoor = 'ftdr',
  AHS = 'ahs',
  HSA = 'hsa'
}

export enum TenantID {
  FTDR = 10,
  AHS = 2,
  HSA = 4
}
