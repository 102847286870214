import { FC } from 'react';
import Spinner from '../Spinner';
import PageContent from '../PageContent';

export interface Props {
  label?: string;
}

const PageLoader: FC<Props> = ({ label }) => {
  return (
    <PageContent className='mt-20 flex justify-center'>
      <Spinner label={label} labelPlacement='bottom' />
    </PageContent>
  );
};

export default PageLoader;
