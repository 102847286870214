import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { TextTemplatesByLanguage } from '@ftdr/blueprint-components-react';
import GenericService from './generic';
import { FooterContent, FooterResponse } from '../types/footer';

const SERVICE_NAME = 'coreproduct-zesty';

class ZestyService extends GenericService {
  constructor() {
    super(SERVICE_NAME);
  }

  getJSON<T = any>(contentPath: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.get<T>(contentPath, {
      responseType: 'json',
      ...(config ?? {})
    });
  }

  getTranslations(): Promise<TextTemplatesByLanguage<string>> {
    return this.getJSON<TextTemplatesByLanguage<string>>('/translations.json').then(
      response => response as TextTemplatesByLanguage<string>
    );
  }

  getFooter(tenant: string): Promise<FooterContent> {
    return this.getJSON<FooterResponse>('/footer.json', {
      params: {
        tenant
      }
    }).then((response: any) => {
      if (response.error) {
        throw new Error(response.error);
      }
      return response.content as FooterContent;
    });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ZestyService();
