import { GetGuestToken } from '@ftdr/crypto-js';
import jwtDecode from 'jwt-decode';
import config from './config';
import { SessionStorageKey } from '../constants/common';

const GUEST_TOKEN_RETRY_ATTEMPTS = 3;

export default class AuthHandler {
  private getGuestToken(attemptsRemaining = 0): Promise<string> {
    return new Promise((resolve, reject) => {
      GetGuestToken({
        baseUrl: config.config?.auth?.guestHost,
        handler: token => {
          if (token instanceof Error) {
            if (attemptsRemaining > 0) {
              this.getGuestToken(attemptsRemaining - 1)
                .then(resolve)
                .catch(reject);
            } else {
              reject(token);
            }
          } else {
            resolve(token as string);
          }
        }
      });
    });
  }

  async setAuthToken() {
    let guestToken = window.sessionStorage.getItem(SessionStorageKey.GuestToken);
    let tokenExpired;
    try {
      if (guestToken) {
        const decodedJWT = jwtDecode(guestToken) as any;
        const unixExp = decodedJWT.exp * 1000;
        if (unixExp < new Date().getTime()) {
          tokenExpired = true;
        }
      }
      if (!guestToken || tokenExpired) {
        guestToken = await this.getGuestToken(GUEST_TOKEN_RETRY_ATTEMPTS);
        guestToken && window.sessionStorage.setItem(SessionStorageKey.GuestToken, guestToken);
      }
    } catch (e) {
      console.error(e);
      throw Error('Error creating token');
    }
  }

  getAuthToken() {
    return window.sessionStorage.getItem(SessionStorageKey.GuestToken);
  }
}
