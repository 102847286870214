import axios from 'axios';
import { ConfigData } from '../types/config';
import { extend } from './utils';

export const fetchConfig = async (): Promise<ConfigData> => {
  const response = await axios.get('/client-configs');
  let configData: ConfigData = await response.data;

  if (configData && Array.isArray(configData)) {
    configData = extend({}, ...configData);
  }

  return configData;
};
