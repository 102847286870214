import { FC } from 'react';
import { LabelPlacementOption, ProgressIndicator, ProgressIndicatorSize } from '@ftdr/blueprint-components-react';

/*
 * Props:
 * - (include same props from the ProgressIndicator component)
 */
export interface Props {
  size?: ProgressIndicatorSize;
  label?: string;
  labelPlacement?: LabelPlacementOption;
  className?: string;
}

const Spinner: FC<Props> = ({ label, labelPlacement, size, className }) => (
  <div className={'flex justify-center items-center ' + className}>
    <ProgressIndicator
      className='m-8'
      variant='circular'
      size={size || 'large'}
      label={label}
      labelPlacement={labelPlacement || 'bottom'}
    />
  </div>
);

export default Spinner;
