import { contractpb } from '@ftdr/selfservice-js-client';
import { SupportedLanguages } from '@ftdr/blueprint-components-react';
import { LANGUAGE } from '../constants/common';

export const getFullPropertyAddress = (property: contractpb.IContractProperty) =>
  `${property.streetAddress},${property.streetAddress2 ? ` ${property.streetAddress2},` : ''} ${property.city}, ${
    property.state
  } ${property.zip}`;

export const getFormattedPrice = (price: number, minDecimal = 2, maxDecimal = 2, locale = 'en-US') =>
  price.toLocaleString(locale, {
    maximumFractionDigits: maxDecimal > minDecimal ? maxDecimal : minDecimal,
    minimumFractionDigits: minDecimal
  });

export const isObject = (obj: Object) => Object.prototype.toString.call(obj) === '[object Object]';

export const extend = (target: any, ...sources: any[]) => {
  if (!isObject(target)) {
    target = {};
  }

  sources.forEach(source => {
    if (isObject(source)) {
      Object.keys(source).forEach(key => {
        if (key in target && isObject(target[key]) && isObject(source[key])) {
          extend(target[key], source[key]);
        } else if (isObject(source[key])) {
          target[key] = extend({}, source[key]);
        } else {
          target[key] = source[key];
        }
      });
    }
  });

  return target;
};

export const getLanguage = () => LANGUAGE.en_US as SupportedLanguages; // TODO: Load from cookie or something to remember user language whenever we support changing user language

export const getMaskedEmail = (email: string): string => {
  const [username, domain] = email.split('@');
  const maskedUsername = username.charAt(0) + '***';
  return `${maskedUsername}@${domain}`;
};
