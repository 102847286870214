import React, { useEffect, useState } from 'react';
import {
  App as BlueprintApp,
  LocalizedTextContextProvider,
  TextTemplatesByLanguage
} from '@ftdr/blueprint-components-react';

import '@ftdr/blueprint-core/themes/ahs-web-app.css';
import AppRoutes from './AppRoutes';
import { StoreContextProvider } from './contexts/StoreContext';
import { IntlProvider } from 'react-intl';
import AuthHandler from './services/auth';
import { fetchConfig } from './utils/configUtils';
import ConfigService from './services/config';
import ZestyService from './services/zesty';
import { getLanguage } from './utils/utils';
import PageLoader from './components/PageLoader';
import { ZestyProvider } from './contexts/ZestyContext';
import ErrorPage from './pages/ErrorPage';
import { FooterContent } from './types/footer';

const App: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [configLoading, setConfigLoading] = useState<boolean>(true);

  const [translations, setTranslations] = useState<TextTemplatesByLanguage<string>>();
  const [footer, setFooter] = useState<FooterContent>();

  const loadConfigAndToken = async () => {
    try {
      const authHandler = new AuthHandler();
      const configData = await fetchConfig();
      ConfigService.config = configData;
      await authHandler.setAuthToken();
      document.title = configData.site.brandLong;
      setConfigLoading(false);
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadConfigAndToken();
  }, []);

  useEffect(() => {
    if (!configLoading) {
      (async () => {
        try {
          const t = await ZestyService.getTranslations();
          setTranslations(t);

          const f = await ZestyService.getFooter(ConfigService.config.site.name);
          setFooter(f);

          setLoading(false);
        } catch (e) {
          setError(true);
          setLoading(false);
        }
      })();
    }
  }, [configLoading, setFooter]);

  return error ? (
    <ErrorPage label='Something went wrong. Please try again.' /> //hard-coded this incase of translations failure from zesty
  ) : !loading && translations && footer ? (
    <BlueprintApp //added this to override the default payment method mfe labels
      appSettings={{
        language: getLanguage(),
        textTemplatesByLanguage: translations
      }}>
      <LocalizedTextContextProvider language={getLanguage()} textTemplatesByLanguage={translations}>
        <StoreContextProvider>
          <IntlProvider locale='en'>
            <ZestyProvider footerContent={footer}>
              <AppRoutes />
            </ZestyProvider>
          </IntlProvider>
        </StoreContextProvider>
      </LocalizedTextContextProvider>
    </BlueprintApp>
  ) : (
    <PageLoader label='Loading...' /> //hard-coded this incase of translations failure from zesty
  );
};

export default App;
